$mintCardWidth: 300px;
$mintCardHeight: 375px;
$playoffBestballPrimaryColor: #75f3f3;

@keyframes revealMintedLineup {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.lineup__wrapper {
  position: relative;
  margin: 1rem 0;
  background-size: $mintCardWidth $mintCardHeight;
  background-repeat: no-repeat;
  background-position: center;
  animation: revealMintedLineup 0.3s ease-in-out;

  .lineup {
    position: relative;
    height: $mintCardHeight;
    max-width: $mintCardWidth;
    width: 100%;

    .lineup__container {
      display: flex;
      width: 100%;
      height: 100%;
    }

    .scaling {
      transform: scale(0.575);
      position: relative;
      top: -10px;
    }

    .lineup__token {
      position: absolute;
      left: 40px;
      bottom: 22px;
      color: #888;
      font-size: 0.7rem;
      font-weight: bold;
    }
  }

  .logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .logo__icon {
      position: relative;
      border: 1px solid #fff;
      border-radius: 50%;
      background: #000;
      width: 62px;
      height: 62px;
      -webkit-box-shadow: 0 0 40px #75f3f3;
      box-shadow: 0 0 40px #75f3f3;
      -webkit-filter: brightness(120%);
      filter: brightness(110%);

      .logo__eliminated {
        position: absolute;
        top: -10px;
        left: -9px;
        height: 80px;
        width: 80px;
        align-items: center;
        justify-content: center;
        color: red;
        z-index: 10;
      }
    }

    .logo__details {
      display: inline-flex;
      width: 100%;
      padding: 1rem 0;
      justify-content: center;
    }
  }
}

.statsModal {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1.5rem;
  height: 92vh;
  width: 100%;

  .header {
    display: inline-flex;
    align-items: center;
    background: #101114 !important;
    padding: 2rem 2rem 1rem;
    height: 90px;
    width: 100%;
  }

  .footer {
    background: #101114 !important;
    padding: 1rem 1.8rem;
  }

  .title {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
  }

  .body {
    overflow: auto;
    max-height: 75vh;
  }
}

.accordion {
  display: flex;
  flex-direction: column;
  padding: 0 0.25rem;

  &:nth-child(odd) {
    background-color: var(--contrast-bg-color);
  }

  .accordionSelected {
    border: 1px solid rgba(#75f3f3, 0.3);
  }

  .accordion__header {
    min-height: 46px;
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 100%;
    font-size: 0.7rem;
    padding: 0;

    &.expanded {
      .score {
        color: #75f3f3;
      }
    }
  }

  .lineup__container {
    margin-right: 0;
  }

  .score {
    color: #fff;
    margin: 0.25rem 0 0 0;
    text-transform: uppercase;
    padding-left: 0.5rem;
    font-size: 0.9rem;
  }

  .icon {
    border: 1px solid #fff;
    border-radius: 50%;
    background: #000;
    width: 45px;
    height: 45px;
    overflow: hidden;
    transform: scale(0.8);
  }

  .weekly {
    padding: 0;
    font-size: 0.8rem;
  }
}
