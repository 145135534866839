@import "~bootstrap/scss/bootstrap";

:root {
  --primary-bg-color: #000;
  --accent-bg-color: #181818;
  --contrast-bg-color: #121212;
  --dark-gray-border-color: #707070;
  --primary-text-color: #fff;
  --primary-border-color: rgba(255, 255, 255, 0.12);
  --muted-text-color: rgba(255, 255, 255, 0.7);
  --success-text-color: #198754;
  --success-text-color-raw: 25, 135, 84;
  --error-text-color: #dc3545;
  --error-text-color-raw: 220, 53, 69;
  --info-text-color: #1da1f2;
  --info-text-color-raw: 29, 161, 242;
  --highlight-text-color: #ffff8f;
  --highlight-text-color-raw: 255, 255, 143;
  --primary-brand-color: #9402fe;
  --primary-brand-color-raw: 148, 2, 254;
  --primary-winning-green: #07bc0c;
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  text-rendering: optimizeLegibility;
}

body {
  background-color: #000;
  color: #fff;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-corner {
  background: #000;
}

::-webkit-scrollbar-track {
  background: #000;
}

::-webkit-scrollbar-thumb {
  background-color: #333;
  border-radius: 20px;
  border: 3px #333;
}

.modal-backdrop.show {
  opacity: 0.85;
}

.modal-content {
  border-radius: 0.9rem;
  background-color: var(--accent-bg-color);
}

.modal-body {
  background-color: var(--accent-bg-color);
  padding: 2rem 3rem;

  @media (max-width: 576px) {
    padding: 1rem 1.5rem;
  }
}

.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.9);
  backdrop-filter: blur(8px);
}

.modal-header {
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
  background-color: var(--contrast-bg-color);
  border-bottom: rgba(255, 255, 255, 0.32);
  border-bottom: none;

  .modal-title {
    padding-left: 1rem;
    font-size: 1.2rem;
  }
  .btn-close {
    color: #fff;
  }
}

.modal-footer {
  background-color: var(--contrast-bg-color);
  border-top: rgba(255, 255, 255, 0.32);
  border-top: none;
  padding: 1rem 2.5rem;
  border-bottom-left-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;

  button {
    min-width: 100px;
    margin-left: 0.5rem;
  }

  @media (max-width: 576px) {
    padding: 1rem;

    button {
      min-width: auto;
    }
  }
}
